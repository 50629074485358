<template>
    <div class="topic-widget">
        <div v-for="(item, index) of this.topItems"
             class="topic-widget-item row g-0"
             v-tooltip="tooltipText(item)"
             :key="index"
             :style="{height: `${itemHeight(item, index)}`}"
        >
<!--            <div v-if="context === 'twitter'"-->
<!--                 class="col-2 h-100 pe-3 d-flex align-items-center overflow-hidden"-->
<!--                 v-html="itemIcon(item)"-->
<!--            ></div>-->
<!--            <div class="col">-->
                <div class="card h-100 text-center rounded-0"
                     :class="{'fw-bold': index === 0}"
                >
                    <div class="my-auto text-fit" v-html="itemLabel(item)"></div>
                </div>
<!--            </div>-->
        </div>
    </div>
</template>

<script lang="ts">
    import {Vue, Component, Prop, toNative} from 'vue-facing-decorator';
    import * as ReportUtilities from 'Utilities/reports';
    import * as Utils from 'Utilities/utils';

    @Component
    class PersonaTopicWidget extends Vue {
        @Prop({
            type: Object,
            default: () => {
                return {};
            },
        })
        topic;

        @Prop({
            type: String,
        })
        context;

        Utils = Utils;
        minItemPercentage: number = 6.0;
        itemCount: number = 5;
        itemHeightPercentMin: number = 10;
        runningPercent: number = 0;

        get currentContext() {
            if (this.topic.hasOwnProperty(this.context)) {
                return this.context;
            }

            return this.topic.hasOwnProperty('twitter') ? 'twitter' : 'instagram';
        }

        // get onError() {
        //     return `this.src = '${this.defaultTwitterImageSrc}'; this.onerror = ''`;
        // }

        get topItems() {
            let topItems = this.topic[this.currentContext].topByIndex
                .slice(0, this.itemCount)
                .filter(item => item.groupCount > 0) // Remove any 0-value items
            ;

            // Determine the ratios
            const indexSum = topItems.reduce((total, item) => total + item.index, 0);
            topItems.map(item => {
                item.percentage = 100 * (item.index / indexSum);
            });

            // Ensure minimum heights
            let remainingPercentage = 100;
            for (let i = 1, length = topItems.length; i <= length; ++i) {
                const item = topItems[length - i]; // Start at the end of the array
                const itemPercentage = Math.max(item.percentage, this.minItemPercentage);
                item.percentage = Math.min(itemPercentage, remainingPercentage);
                remainingPercentage -= itemPercentage;
            }

            return topItems;
        }

        itemHeight(item) {
            // return `${item.height}px`;
            return `${item.percentage}%`;
        }

        // itemIcon(item): string {
        //     let itemIcon = ReportUtilities.socialActionIcon(item, 'mx-auto');
        //     const itemUrl = ReportUtilities.socialActionUrl(item);
        //     if (itemUrl) {
        //         itemIcon = `<a href="${itemUrl}" target="_new">${itemIcon}</a>`;
        //     }
        //
        //     return itemIcon;
        // }

        itemLabel(account) {
            let itemLabel = account.displayName;
            const itemUrl = ReportUtilities.socialActionUrl(account);
            if (itemUrl) {
                itemLabel = `<a href="${itemUrl}" target="_new">${itemLabel}</a>`;
            }

            return itemLabel;
        }

        // itemUrl(account) {
        //     const accountName = account.item.replace('@', '');
        //     // Currently only twitter items are linked
        //     return ['twitterFollow', 'twitterMention'].includes(account.dataType) ?
        //         `https://twitter.com/${accountName}` :
        //         null;
        // }

        tooltipText(item) {
            return Utils.formatValue(item.index, 'indexLikelihood');
        }
    }
    export default toNative(PersonaTopicWidget);
</script>

<style lang="scss">
    @import '../../../app/styles/variables';

    .topic-widget {
        color: $gray-75;
        height: 500px;

        &-item {
            margin: 0 !important;
            &:first-child .card {
                background-color: $green-25;
                border-color: $success !important;
            }

            &:not(:first-child) .card {
                border-top: none;
            }
        }

        .text-fit {
            word-break: break-all;
        }
    }
</style>

<template>
    <div class="mb-n5" data-intent="wedge-chart-section">
        <div style="height: 0;" v-if="uuid">
            <svg>
                <pattern
                    height="8"
                    :id="`diagonal_stripes_${uuid}`"
                    patternTransform="rotate(40)"
                    patternUnits="userSpaceOnUse"
                    width="8"
                    x="0"
                    y="0"
                >
                    <rect x="0" y="0" width="2" height="8"
                          style="stroke:none; fill: #eaeaea;"></rect>
                    <rect x="2" y="0" width="2" height="8"
                          style="stroke:none; fill: white"></rect>
                    <rect x="4" y="0" width="2" height="8"
                          style="stroke:none; fill: #eaeaea;"></rect>
                    <rect x="6" y="0" width="2" height="8"
                          style="stroke:none; fill: white"></rect>
                </pattern>
            </svg>
        </div>

        <div v-for="(dataItem, index) in data.items"
             :key="dataItem.key"
             class="wedge-chart-display mb-5 text-start"
        >
            <div class="fact-description clearfix">
                <font-awesome-icon
                    v-if="dataItem.tooltip.info?.length"
                    :icon="['light', 'circle-question']"
                    class="float-end"
                    v-tooltip="dataItem.tooltip.info"
                />
                <div v-html="dataItem.description"></div>
<!--                {{ dataItem.description }}-->
            </div>

            <div class="svg-container" v-if="uuid">
                <svg xmlns="http://www.w3.org/2000/svg" version="1.1"
                     class="scale" x="0" y="0"
                     viewBox="0 0 408.7 39.8" xml:space="preserve"
                >
                    <path
                        d="M406.7 39.8H2c-2.6 0-2.7-3.8-0.2-4L406.5 0c1.2-0.1 2.2 0.8 2.2 2v35.8C408.7 38.9 407.8 39.8 406.7 39.8"
                        :fill="`url(#diagonal_stripes_${uuid})`"></path>
                    <path
                        d="M406.7 39.8H2c-2.6 0-2.7-3.8-0.2-4L406.5 0c1.2-0.1 2.2 0.8 2.2 2v35.8C408.7 38.9 407.8 39.8 406.7 39.8"
                        :fill="`url(#${dataItem.key}_grad_${uuid})`"></path>
                    <defs>
                        <linearGradient :id="`${dataItem.key}_grad_${uuid}`" x1="0"
                                        x2="100%"
                                        y1="0"
                                        y2="0">
                            <stop :offset="`${dataItem.wedgeStop}`"
                                  :stop-color="wedgeColor(dataItem)"/>
                            <stop :offset="`${dataItem.wedgeStop}`"
                                  stop-color="transparent"/>
                        </linearGradient>
                    </defs>
                </svg>

                <svg class="user-indicator"
                     enable-background="new 0 0 16.438 20"
                     height="20"
                     :id="`USER_INDICATOR_${index}_${uuid}`"
                     :style="`left: ${dataItem.wedgeStop}`"
                     v-tooltip="dataItem.tooltip.graph"
                     version="1.1"
                     viewBox="0 0 16.44 20"
                     width="16.44"
                     x="0"
                     xml:space="preserve"
                     xmlns="http://www.w3.org/2000/svg"
                     xmlns:xlink="http://www.w3.org/1999/xlink"
                     y="0"
                >
                    <g opacity="0.75">
                        <defs>
                            <rect :id="`USER_INDICATOR_${index}_A_${uuid}`" width="16.44" height="20"/>
                        </defs>
                        <clipPath :id="`USER_INDICATOR_${index}_B_${uuid}`">
                            <use :xlink:href="`#USER_INDICATOR_${index}_A_${uuid}`" overflow="visible"/>
                        </clipPath>
                        <path :clip-path="`url(#USER_INDICATOR_${index}_B_${uuid})`" fill="#FFFFFF"
                              d="M2.9 19c-1.02 0-1.87-0.83-1.9-1.85 -0.05-2.08 0.6-3.87 1.89-5.19 0.53-0.54 1.16-0.99 1.86-1.32C3.54 9.63 2.78 8.12 2.78 6.45 2.78 3.44 5.22 1 8.22 1c3 0 5.44 2.44 5.44 5.45 0 1.67-0.77 3.19-1.97 4.19 0.7 0.34 1.33 0.78 1.86 1.33 1.28 1.31 1.93 3.1 1.89 5.19C15.41 18.17 14.56 19 13.54 19H2.9z"/>
                        <path
                            :clip-path="`url(#USER_INDICATOR_${index}_B_${uuid})`" fill="#FFFFFF"
                            d="M8.22 2c2.45 0 4.44 2 4.44 4.45 0 2.35-1.83 4.28-4.13 4.44 1.73 0.07 3.25 0.69 4.31 1.78 1.09 1.11 1.64 2.66 1.6 4.46C14.43 17.61 14.03 18 13.54 18H2.9c-0.49 0-0.89-0.39-0.9-0.88 -0.04-1.81 0.51-3.35 1.6-4.46 1.06-1.08 2.58-1.71 4.31-1.77 -2.31-0.16-4.14-2.09-4.14-4.44C3.78 4 5.77 2 8.22 2M8.22 0C4.67 0 1.78 2.89 1.78 6.45c0 1.49 0.51 2.87 1.38 3.97 -0.35 0.25-0.68 0.53-0.98 0.84 -1.47 1.51-2.22 3.55-2.17 5.91C0.04 18.73 1.34 20 2.9 20h10.64c1.56 0 2.86-1.27 2.9-2.83 0.06-2.35-0.7-4.4-2.17-5.91 -0.3-0.31-0.63-0.59-0.98-0.84 0.86-1.1 1.38-2.48 1.38-3.97C14.66 2.89 11.77 0 8.22 0"/>
                    </g>
                    <path fill="#454545"
                          d="M8.53 10.89c2.31-0.16 4.14-2.09 4.14-4.44C12.66 4 10.67 2 8.22 2S3.78 4 3.78 6.45c0 2.35 1.83 4.28 4.14 4.44 -1.73 0.07-3.25 0.69-4.31 1.78 -1.09 1.11-1.64 2.66-1.6 4.46C2.01 17.61 2.41 18 2.9 18h10.64c0.49 0 0.89-0.39 0.9-0.88 0.04-1.81-0.51-3.35-1.6-4.46C11.77 11.58 10.26 10.95 8.53 10.89"/>
                </svg>
            </div>

            <div v-if="index === 0" class="axis-labels mt-1 w-100">
                <div data-intent="min">Unlikely</div>
                <div data-intent="median">Average</div>
                <div data-intent="max">Likely</div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import {Vue, Component, Prop, toNative} from 'vue-facing-decorator';
    import * as Utils from 'Utilities/utils';
    import * as ReportUtilities from "Utilities/reports";
    import FontAwesomeIcon from "Components/common/font-awesome-icon";

    @Component({
        components: {
            FontAwesomeIcon
        }
    })
    class WedgeChartSection extends Vue {
        @Prop({
            type: Object,
            default: () => {},
        })
        data;

        uniqueId?: string | null = null;

        get uuid() {
            if (!this.uniqueId) {
                this.uniqueId = Utils.uuidv4();
            }

            return this.uniqueId;
        }

        wedgeColor(dataItem) {
            return dataItem.color
                || this.data.color
                // || dataItem.colors?.fillSequence[dataItem.fillSequence || 0]
                || ReportUtilities.reportColors.wedge.fill;
        }
    }
    export default toNative(WedgeChartSection);
</script>

<template>
    <div
        class="modal"
        :class="modalContainerClasses"
        :data-bs-backdrop="backdropValue"
        :data-bs-keyboard="hideOnEscape.toString()"
        :id="id"
        tabindex="-1"
    >
        <div class="modal-dialog" :class="modalDialogClasses">
            <div class="modal-content" :class="contentClass">
                <div class="modal-header" :class="headerClass">
                    <h3 v-if="$slots.title" class="modal-title strong" :class="titleClass"><slot name="title"/></h3>
<!--                    <slot name="close">-->
                        <button
                            aria-label="Close"
                            class="btn-close"
                            data-bs-dismiss="modal"
                            type="button"
                            v-tooltip="`Close`"
                        ></button>
<!--                    </slot>-->
                </div>
                <div class="modal-body" :class="bodyClass">
                    <slot name="body"/>
                </div>
                <div v-if="$slots.footer" class="modal-footer" :class="footerClass">
                    <slot name="footer"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
    import {onMounted, computed} from 'vue';

    const emit = defineEmits(['hidden', 'hide', 'show', 'shown']);
    const props = defineProps({
        backdrop: {
            default: true,
            type: Boolean,
        },
        bodyClass: {
            default: '',
            type: String,
        },
        centered: {
            default: false,
            type: Boolean,
        },
        contentClass: {
            default: '',
            type: String,
        },
        fade: {
            default: true,
            type: Boolean,
        },
        footerClass: {
            default: '',
            type: String,
        },
        headerClass: {
            default: '',
            type: String,
        },
        hideOnBackdrop: {
            default: true,
            type: Boolean,
        },
        hideOnEscape: {
            default: true,
            type: Boolean,
        },
        id: {
            required: true,
            type: String,
        },
        scrollable: {
            default: false,
            type: Boolean,
        },
        titleClass: {
            default: '',
            type: String,
        },
    });

    const backdropValue = computed(() => {
        if (!props.hideOnBackdrop) {
            return 'static';
        }

        return props.backdrop;
    });

    const modalContainerClasses = computed(() => {
        return {
            fade: props.fade,
        };
    });

    const modalDialogClasses = computed(() => {
        return {
            'modal-dialog-centered': props.centered,
            'modal-dialog-scrollable': props.scrollable,
        };
    });

    onMounted(() => {
        const modalElement = document.getElementById(props.id);
        if (modalElement) {
            modalElement.addEventListener('hidden.bs.modal', event => {
                emit('hidden');
            });
            modalElement.addEventListener('hide.bs.modal', event => {
                emit('hide');
            });
            modalElement.addEventListener('show.bs.modal', event => {
                emit('show');
            });
            modalElement.addEventListener('shown.bs.modal', event => {
                emit('shown');
            });
        }
    });
</script>

import * as Koa from 'koa';
import Router from 'koa-router';
import moment from 'moment';
import api from 'Server/api';
import CustomRequestConfig from 'axios';
import { encrypt, decrypt, getPepperedHash } from 'Server/crypto';
import { AxiosResponse } from 'axios';
import { User } from 'Stores/user';
import { httpSuccessCodeMatcher, httpClientErrorCodeMatcher } from 'Utilities/regex-matchers';
import { invalidAuthResponse, RequestError, Status } from 'Utilities/immutables';

const uuidPattern: string = '[a-f0-9-]{24,36}'; // TODO: why doesn't importing from Server/api work in this file alone?

interface Auth {
    key: string,
    user: User,
    token?: string,
    expires?: Date
}

const router = new Router();
export const aid: string = process.env.APP_ID || '';
export const isDev: boolean = process.env.NODE_ENV === 'development';
export const proxyAuthError: string = 'Proxy authentication error';
export interface MultifactorAuth {
    username: string;
    token: string;
    rememberMe: boolean;
    expiration?: Date;
}
export const cookieSamesite = 'strict';

export const setMultifactorCookie = ( ctx: Koa.Context, auth: MultifactorAuth ): void => {
    try {
        const authObj: Auth = { key: auth.username, token: auth.token } as Auth;
        const expiration: Date = auth.rememberMe ? moment().add( 180, 'day' ).toDate() : moment().add( 1, 'day' ).toDate();
        const encryptedMultifactorAuth: string = encrypt( authObj );
        const encryptedMultifactorAuthCookieOptions: any = {
            httpOnly: true,
            expires: expiration,
            secure: !isDev,
            sameSite: cookieSamesite,
            overwrite: true
        };

        ctx.cookies.set( 'multifactorAuth', encryptedMultifactorAuth.toString(), encryptedMultifactorAuthCookieOptions );
    } catch ( error ) {
        console.error( error.stack );
        throw error;
    }
}

export const updatePersistedUser = ( ctx: Koa.Context, update: any ) => {
    if ( !ctx.request.body.currentUserId && ctx.cookies.get( 'pbAuth' ) && update.data  ) {
        const user: any = update.data;
        const pbAuth: any = ctx.cookies.get( 'pbAuth' ) ? JSON.parse( decrypt( ctx.cookies.get( 'pbAuth' ) ) ) : {};

        if ( pbAuth.key === aid && pbAuth.user ) {
            if ( user.data?.accounts ) {
                delete( user.data.accounts );
            }
            pbAuth.user = user.data;
            const encryptedPBAuth: string = encrypt( pbAuth );
            const pbCookieOptions: any = {
                httpOnly: false,
                expires: new Date( pbAuth.expires ),
                secure: !isDev,
                sameSite: cookieSamesite
            };

            ctx.cookies.set( 'pbAuth', encryptedPBAuth.toString(), pbCookieOptions );
        } else {
            ctx.throw( 401, proxyAuthError );
        }
    }
}

router.post( '/check', async ( ctx: Koa.Context ) => {
    let authenticated: boolean = false;
    let body: any = ctx.request.body;

    if ( ( body && body.user ) && ctx.cookies.get( 'pbAuth' ) && ctx.cookies.get( 'authToken' ) ) {
        const pbAuth: any = JSON.parse( decrypt( ctx.cookies.get( 'pbAuth' ) ) ) || {};
        if ( pbAuth.key === aid && ( pbAuth.user.id === body.user.id ) ) {
            authenticated = true;
        }
    } else {
        console.error( 401, proxyAuthError );
    }

    ctx.body = authenticated;
} );

router.post( '/login', async ( ctx: Koa.Context ) => {
    let body: any = ctx.request.body;

    if ( body.username ) {
        try {
            if ( body.password && body.salt ) {
                if ( ctx.cookies.get( 'multifactorAuth' ) ) {
                    const multifactorAuth: any = JSON.parse( decrypt( ctx.cookies.get( 'multifactorAuth' ) ) ) || {};

                    if ( multifactorAuth ) {
                        if ( multifactorAuth.key === body.username ) {
                            body.token2fa = multifactorAuth.token;
                        } else {
                            ctx.cookies.set( 'multifactorAuth', '' );
                        }
                    } else {
                        ctx.throw( 500, `${RequestError.UNABLE_TO_PARSE} multifactorAuth` );
                    }
                }

                body.password = ( body.salt && body.password ) ? await getPepperedHash( body ) : body.password;
                delete body.salt;
            }

            const response: AxiosResponse = await api.getAxiosInstance.post( api.getApiResourceUri( '/login' ), body, {} );
            let auth: any = response.data || response;

            if ( httpSuccessCodeMatcher.test( auth.code) ) {
                auth = auth.data;

                if ( auth.salt ) {
                    // Valid credential check
                    ctx.body = auth;
                } else if ( auth.data && auth.data.user && auth.data.token ) {
                    const expiration: Date = moment().add( 1, 'day' ).toDate();
                    const authObj: Auth = { key: aid, user: auth.data.user, expires: expiration };
                    const encryptedAuth: string = encrypt( authObj );
                    const encryptedAuthToken: string = encrypt( auth.data.token );
                    const pbCookieOptions: any = {
                        httpOnly: false,
                        expires: expiration,
                        secure: !isDev,
                        sameSite: cookieSamesite
                    };
                    const authCookieOptions: any = {
                        httpOnly: true,
                        expires: expiration,
                        secure: !isDev,
                        sameSite: cookieSamesite
                    };

                    if ( !ctx.cookies.get( 'multifactorAuth' ) ) {
                        const newMultifactorAuth: MultifactorAuth = {
                            username: auth.data.user.email,
                            token: auth.data.token.id,
                            rememberMe: body.rememberMe
                        }

                        setMultifactorCookie( ctx, newMultifactorAuth );
                    }

                    delete auth.data.user.hash;
                    ctx.cookies.set( 'pbAuth', encryptedAuth.toString(), pbCookieOptions );
                    ctx.cookies.set( 'authToken', encryptedAuthToken.toString(), authCookieOptions );
                    ctx.body = auth;
                }
                ctx.body = auth;
            } else if ( httpClientErrorCodeMatcher.test( auth.code ) ) {
                ctx.body = auth;
            } else {
                ctx.throw( 500, RequestError.INVALID_RESPONSE );
            }
        } catch ( error ) {
            console.error( error.stack );
            ctx.throw( 500, error.stack );
        }
    } else {
        console.error( RequestError.INVALID_REQUEST );
        ctx.body = invalidAuthResponse;
    }
} );

router.post( '/2fa/verification', async ( ctx: Koa.Context ) => {
    let body: any = ctx.request.body;

    if ( body.code ) {
        try {
            const response: AxiosResponse = await api.getAxiosInstance.post( api.getApiResourceUri( '/login/2fa' ), body, {} );
            const auth: any = response.data || response;

            if ( auth ) {
                if ( auth.data && auth.data.token ) {
                    if ( body.action === 'email-update' ) {
                        // Clear current 2fa cookie--force login with new
                        ctx.cookies.set( 'multifactorAuth', '' );
                    } else {
                        // Valid 2fa code
                        const newMultifactorAuth: MultifactorAuth = {
                            username: body.username,
                            token: auth.data.token.id,
                            rememberMe: body.rememberMe
                        }

                        setMultifactorCookie( ctx, newMultifactorAuth );
                    }
                }

                ctx.body = auth;
            } else {
                ctx.throw( 500, `${RequestError.INVALID_RESPONSE}` );
            }
        } catch ( error ) {
            console.error( error.stack );
            ctx.throw( 500, error.stack );
        }
    } else {
        console.error( RequestError.INVALID_REQUEST );
        ctx.body = invalidAuthResponse;
    }
} );

router.get( '/logout', async ( ctx: Koa.Context ) => {
    if ( ctx.cookies.get( 'pbAuth' ) && ctx.cookies.get( 'authToken' ) ) {
        ctx.cookies.set( 'pbAuth', '' );
        ctx.cookies.set( 'pbAuth.sig', null );
        ctx.cookies.set( 'pbAuthAt', '' );
        ctx.cookies.set( 'pbAuthAt.sig', null );
        ctx.cookies.set( 'authToken', '' );
        ctx.cookies.set( 'authToken.sig', null );

        if ( ctx.query.rememberMe === 'false' ) {
            ctx.cookies.set( 'multifactorAuth', '' );
            ctx.cookies.set( 'multifactorAuth.sig', null );
        }

        ctx.body = {
            success: true
        };
    } else {
        ctx.body = {};
        console.error( 302, 'No auth set to clear.' );
    }
} );

router.post( `/shared-key`, async ( ctx: Koa.Context ) => {
    let {accountId, token} = ctx.request.body;

    // Set shared key cookies
    const httpOnly = false,
        secure = !isDev,
        expires: Date = moment().add( 1, 'day' ).toDate(),
        sameSite = 'strict';
    const systemUser: User = { id: '', email: 'it@wiland.com', currentAccountId: '', status: '' } as User;
    const authObj: Auth = {
        key: aid,
        user: systemUser,
        // expires: expiration
    };
    const encryptedAuth: string = encrypt( authObj );

    const encryptedAuthToken: string = encrypt( token );
    const pbCookieOptions: any = {
        httpOnly,
        expires,
        secure,
        sameSite,
    };
    const authCookieOptions: any = {
        httpOnly,
        expires,
        secure,
        sameSite,
    };

    ctx.cookies.set( 'pbAuthShared', encryptedAuth.toString(), pbCookieOptions );
    ctx.cookies.set( 'authTokenShared', encryptedAuthToken, authCookieOptions );
    ctx.body = {
        accountId,
        token
    };
} );

router.get( '/user', async ( ctx: Koa.Context ) => {
    const requestConfig: CustomRequestConfig = { headers: ctx.request.headers, cookies: ctx.cookies };
    let user: any = {};

    if ( ctx.request.query.state === 'persisted' ) {
        try {
            const pbAuth: any = ctx.cookies.get( 'pbAuth' ) ? JSON.parse( decrypt( ctx.cookies.get( 'pbAuth' ) ) ) : {};

            if ( pbAuth.key === aid ) {
                const response = await api.getAxiosInstance.get(api.getApiResourceUri(`/users/${pbAuth.user.id}/accounts`), requestConfig);

                user = pbAuth.user;
                user.accounts = response.data?.data || [];
            } else {
                console.error( 401, proxyAuthError );
                ctx.body = proxyAuthError;
            }
        } catch ( error ) {
            // TODO: Need to add logger
            console.error( error );
            ctx.throw( 500, error );
        }
    } else {
        console.error( 401, proxyAuthError );
        ctx.body = proxyAuthError;
    }

    ctx.body = user;
} );

router.get( `/users/:id(${uuidPattern})/tokens/:token(${uuidPattern})`, async ( ctx: Koa.Context ) => {
    // console.log(
    //     `🌴🌴🌴 TOKEN RETRIEVAL PATTERN: /users/:id(${uuidPattern})/tokens/:token(${uuidPattern})`,
    //     api.getApiResourceUri( `/accounts/${ctx.query.accountId}/users/${ctx.params.id}/tokens/${ctx.params.token}?status=${ctx.query.status}` )
    // );
    try {
        const requestConfig: CustomRequestConfig = { headers: ctx.request.headers, cookies: ctx.cookies };
        const response = await api.getAxiosInstance.get( api.getApiResourceUri( `/accounts/${ctx.query.accountId}/users/${ctx.params.id}/tokens/${ctx.params.token}?status=${ctx.query.status}` ), requestConfig );
        ctx.body = response.data;
        // console.log('👍👍👍 TOKEN COMPLETE');
    } catch ( error ) {
        // TODO: Need to add logger
        // console.log('👎 TOKEN FAILURE!');
        console.error( error );
        ctx.throw( 500, error );
    }
} );

router.patch( `/users/:id(${uuidPattern})/reset`, async (ctx: Koa.Context) => {
    try {
        const requestConfig: CustomRequestConfig = {headers: ctx.request.headers, cookies: ctx.cookies};

        if ( ctx.request.body.salt && ctx.request.body.password ) {
            ctx.request.body.password = await getPepperedHash( ctx.request.body );
        }
        delete( ctx.request.body.currentAccountId );
        const body: any = ctx.request.body;
        const endpoint = api.getApiResourceUri(`/users/${ctx.params.id}/reset`);
        const response = await api.getAxiosInstance.patch(endpoint, body, requestConfig);

        updatePersistedUser( ctx, response );
        ctx.body = response.data;
    } catch (error) {
        // TODO: Need to add logger
        console.error( `User PATCH error`, error );
        ctx.throw( 500, error );
    }
});

router.put( `/users/:id(${uuidPattern})/register`, async ( ctx: Koa.Context ) => {
    try {
        const requestConfig: CustomRequestConfig = { headers: ctx.request.headers, cookies: ctx.cookies };
        const body: any = ctx.request.body;
        const endpoint = api.getApiResourceUri( `/users/${ctx.params.id}/register` );
        const response = await api.getAxiosInstance.put( endpoint, body, requestConfig );

        if ( response.data  ) {
            const user: any = response.data?.data;

            if ( user.id && body.userProfile ) {
                // Set new user's multi-factor cookie
                if ( user.token2fa && ( body.userProfile.status === Status.INVITED ) ) {
                    const newMultifactorAuth: MultifactorAuth = {
                        username: body.userProfile.email,
                        token: user.token2fa,
                        rememberMe: body.rememberMe
                    }

                    setMultifactorCookie( ctx, newMultifactorAuth );
                }

                ctx.body = response.data;
                return true;
            } else {
                throw RequestError.INVALID_REQUEST;
            }
        } else {
            throw RequestError.INVALID_RESPONSE;
        }
    } catch ( error ) {
        // TODO: Need to add logger
        console.error( error.stack );
        ctx.throw( 500, error.stack );
    }
} );

router.patch(`/users/:id(${uuidPattern})/register`, async (ctx: Koa.Context) => {
    try {
        const requestConfig: CustomRequestConfig = {headers: ctx.request.headers, cookies: ctx.cookies};

        if ( ctx.request.body.salt && ctx.request.body.password ) {
            ctx.request.body.password = await getPepperedHash( ctx.request.body );
        }

        const body: any = ctx.request.body;
        const endpoint = api.getApiResourceUri(`/users/${ctx.params.id}/register`);
        const response = await api.getAxiosInstance.patch(endpoint, body, requestConfig);

        ctx.body = response.data;

        if ( response.data  ) {
            const user: any = response.data;

            if ( user.id ) {
                return true;
            } else {
                return false;
            }
        }
    } catch (error) {
        // TODO: Need to add logger
        console.error(`User PATCH error`, error);
        ctx.throw(500, error);
    }
});

router.put( `/users/:id(${uuidPattern})/verification`, async ( ctx: Koa.Context ) => {
    try {
        const requestConfig: CustomRequestConfig = { headers: ctx.request.headers, cookies: ctx.cookies };
        const body: any = ctx.request.body;
        const endpoint = api.getApiResourceUri( `/users/${ctx.params.id}/verification` );
        const response = await api.getAxiosInstance.put( endpoint, body, requestConfig );
        ctx.body = response.data;
    } catch ( error ) {
        // TODO: Need to add logger
        console.error( `User verification error`, error );
        ctx.throw( 500, error );
    }
} );

router.put( `/users/:username/reset`, async ( ctx: Koa.Context ) => {
    try {
        const requestConfig: CustomRequestConfig = { headers: ctx.request.headers, cookies: ctx.cookies };
        const body: any = ctx.request.body;
        const endpoint = api.getApiResourceUri( `/users/${ctx.params.username}/reset` );
        const response = await api.getAxiosInstance.put( endpoint, body, requestConfig );
        ctx.body = response.data;
    } catch ( error ) {
        // TODO: Need to add logger
        console.error( `User reset error`, error );
        ctx.throw( 500, error );
    }
} );

export default router;
